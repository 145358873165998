// Overall
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column; }

main {
  flex: 1 0 auto; }

.container {
  width: 90%; }

.flower-background {
  background: url(../res/flower.png) repeat left top; }

.userView {
  background-color: rgb(238, 110, 115); }

// Header
header {
  a:hover {
    text-decoration: none; }

  .page-title {
    background-color: rgba(52, 58, 64, 1);
    color: white;
    display: inline-block;
    padding: 20px; } }

a {
  &.button-collapse {
    &.top-nav {
      position: absolute;
      text-align: center;
      height: 48px;
      width: 48px;
      top: 0;
      float: none;
      font-size: 36px;
      z-index: 999;
      line-height: 80px; } } }

nav {
  &.top-nav {
    height: 192px; } }

// Footer
footer.page-footer {
  margin-top: 10px;

  .row {
    margin-bottom: 0px; }

  p {
    margin-top: 5px; } }

// Misc
blockquote {
  border-left-color: teal; }

main {
  ul {
    &:not(.browser-default) {
      padding-left: 40px;

      li {
        list-style-type: disc; } } } }

@media only screen and (max-width: 992px) {
  header,
  main,
  footer {
    padding-left: 0; }

  nav {
    .nav-wrapper {
      text-align: center; } } }

@media only screen and (max-width: 600px) {
  nav {
    .page-title {
      font-size: 20px; } } }
